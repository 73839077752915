* {
    margin: 0;
    padding: 0;
  }  

  .imperial {
    background: #538ace;
  }

  .imperial body {
    background: #538ace;
  }

  form {
    visibility: hidden !important;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #d2d2d2 !important;
  }

  .imperial-logo-container {
    display: flex;
    justify-content: center;
    padding-bottom: 27px;
  }

  .imperial form {
    visibility: visible !important;
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: #f4f7fa;
    padding: 20px;
    margin-top: 30px;
  }

  .imperial .page-not-found-form {
    visibility: visible !important;
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: #f4f7fa;
    padding: 20px;
    margin-top: 30px;
  }

  .piggly-wiggly {
    background: #f2f3f3;
  }

  .piggly-wiggly body {
    background: #f2f3f3;
  }

  .piggly-wiggly form {
    visibility: visible !important;
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin-top: 30px;
    border: #434a4c 1px solid;
    border-radius: 20px;   
    box-shadow: 0px 25px 50px -40px rgb(0 0 0 / 50%); 
  }

  .piggly-wiggly .page-not-found-form {
    visibility: visible !important;
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin-top: 30px;
    border: #434a4c 1px solid;
    border-radius: 20px;   
    box-shadow: 0px 25px 50px -40px rgb(0 0 0 / 50%); 
  }

  .pigglywiggly-logo-container {
    display: flex;
    justify-content: center;
    padding-bottom: 27px;
  }

  .pigglywiggly-logo-container img {
    width: 100%;
  }

  .piggly-wiggly .page-not-found-form .pigglywiggly-logo-container img {
    width: 270px;
  }



 






  